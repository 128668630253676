import { selectCameraBSTabIndex, selectCameraIntent } from "@/store/slices/offCanvas";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const WHITELISTED_QUERY_PARAMS: { [pathname: string]: string[] } = {
  "/course": ["Exam", "Class"],
}

const NOINDEX_ROUTES: string[] = [
  "/whatsapp/qa/",
  "/menti",
  "/checkout",
  "/my-course",
  "/home",
  "/payment",
  "/testseries/",
  "/scholarship/",
  "/mocktest/",
  "/profile",
  "/wallet",
  "/change-pin",
  "/pdf-viewer",
  "/allen-"
];

const DeferredHead = (props: {
  resolvedUrl: string;
}) => {
  const router = useRouter();
  const isCameraBSOpen = useSelector(selectCameraIntent);
  const cameraBSTabIndex = useSelector(selectCameraBSTabIndex);

  const isCameraOpen = isCameraBSOpen && cameraBSTabIndex === 1;

  const hasNonWhitelistedQuery = () => {
    // in ssr query won't get resolved 
    const isSSR = !router.isReady;

    if (!WHITELISTED_QUERY_PARAMS[router.pathname]) {
      return (isSSR && props.resolvedUrl) ? props.resolvedUrl.split("?").length > 1 : router.asPath.split("?").length > 1;
    }
    return router.asPath.split("?")[1]?.split("&").some(query => {
      const key = query.split("=")[0];
      return !WHITELISTED_QUERY_PARAMS[router.pathname].includes(key);
    });
  };

  const noIndexPage = () => {
    for (const route of NOINDEX_ROUTES) {
      if (router.asPath.startsWith(route)) {
        return true;
      }
    }
    return false;
  }

  const [noindex, setnoindex] = useState<boolean>(hasNonWhitelistedQuery() || noIndexPage());
  useEffect(() => {
    setnoindex(hasNonWhitelistedQuery());
  }, [router.asPath]);

  // TODO: will work but need to test them - even useMemo can be removed - probably need to test that too
  // const noindex = useMemo(() => {
  //   return hasNonWhitelistedQuery() || noIndexPage();
  // }, [router.asPath])

  const viewportMeta = useMemo(() => {
    if (!isCameraOpen) {
      return { name: "viewport", content: "width=device-width, initial-scale=1" };
    }
    return { name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" };
  }, [isCameraOpen]);

  return (
    <NextSeo noindex={noindex} additionalMetaTags={[viewportMeta]} />
  )
}

export default DeferredHead;