import { CacheBackend } from "./enums";

export const STATIC_CDN = "https://static.doubtnut.com";
export const STATIC_CDN_WEB = "https://static.doubtnut.com/web";
export const STATIC_CDN_WEB_SVG = "https://static.doubtnut.com/web_svg";
export const STATIC_CDN_WEB_GIF = "https://static.doubtnut.com/web_gif";
export const STATIC_CDN_IMAGES = "https://static.doubtnut.com/images";
export const STATIC_CDN_WEB_THUMB = "https://static.doubtnut.com/web-thumb";
export const STATIC_CDN_ASSETS = "https://d1bwwumizb0ypj.cloudfront.net";
export const STATIC_CDN_LOTTIE = "https://static.doubtnut.com/dotlottie";
export const VIDEO_CDN = "https://videos.doubtnut.com";
export const VIDEO_CDN_AZURE = "https://videos-azure.doubtnut.com";

export const ELASTIC_HOST_NAME = "https://es-search.internal.doubtnut.com";
export const ELASTIC_INDEX_NAME = "question_bank";

export const WEB_API = "https://web-api.doubtnut.com";
export const MICRO_API = "https://micro.doubtnut.com";
export const MICRO_INTERNAL_API = "https://micro.internal.doubtnut.com";
export const BACKEND_API = process.env.BACKEND_API || "https://api.doubtnut.com";
export const DEEPLINK_HOST = "https://dl.doubtnut.com";
export const DEEPLINK_PREFIX = "https://dl.doubtnut.com/l";

export const FRONTEND_HOST = "https://www.doubtnut.com";
export const ALLEN_DIGITAL_HOST = "https://digital.allen.ac.in";

export const UPDATED_ON = "27-06-2022";
export const NEW_ARTICLE_DATE = "2023-01-01T21:11Z";

export const CACHE_BACKEND_SRC = process.env.NEXT_PUBLIC_CACHE_BACKEND_SRC || CacheBackend.GCS;
export const CACHE_BACKEND_DEST = process.env.NEXT_PUBLIC_CACHE_BACKEND_DEST || CacheBackend.GCS;

export const BUCKET_NAME = "dn-web-videopage-data";

export const AD_SIZES_GPT = [
  [728, 90],
  [336, 280],
  [300, 250],
  [300, 50],
  [160, 600],
  [1024, 768],
  [970, 250],
  [970, 90],
  [768, 1024],
  [480, 320],
  [468, 60],
  [320, 480],
  [320, 100],
  [320, 50],
  [300, 600],
  [300, 100],
  [250, 250],
  [234, 60],
  [200, 200],
  [180, 150],
  [125, 125],
  [120, 600],
  [120, 240],
  [120, 60],
  [88, 31],
  [980, 120],
  [980, 90],
  [950, 90],
  [930, 180],
  [750, 300],
  [750, 200],
  [750, 100],
  [580, 400],
  [250, 360],
  [240, 400],
  [970, 66],
  [960, 90],
  [640, 480],
  [400, 300],
  [300, 31],
  [300, 75],
  [300, 1050],
  [292, 30],
  [240, 133],
  [220, 90],
  [216, 36],
  [216, 54],
  [200, 446],
  [168, 28],
  [168, 42],
  [120, 20],
  [120, 30],
  [120, 90],
  [180, 600],
  [360, 75],
  [300, 175],
  [400, 100],
  [360, 250],
  [360, 280],
  [393, 280],
  [412, 280],
  [384, 280],
  [393, 250],
];

export const AD_SIZES_PUBLIFT = [
  [320, 100],
  [320, 50],
  [300, 100],
  [300, 50],
  [300, 250],
  [336, 280],
  [300, 600],
  [468, 60],
  [970, 90],
  [728, 90],
  [970, 250],
];

export const allHeadings: any = {
  default: {
    similarQuestions: "Similar Questions",
    related: "Related",
    video: "Videos",
    latest_blog_post: "Latest Blog Post",
    microconcepts: "FAQs",
    view_all: "View All",
    latest_videos: "Latest Videos",
    text_solution: "Text Solution",
    solution: "Solution",
    answer: "Answer",
    related_answer: "Related Answer",
    class: "Class",
    image_solution: "Image Solution",
    very_imporatant_questions: "Very Imporatant Questions",
    imporatant: "Imporatant",
    question: "Question",
    relatedPlaylists: "Related Playlists",
    videoSolution: "Video Solution",
    similarPracticeProblems: "Similar Practice Problems",
    knowledgeCheck: "Knowledge Check",
    topperSolved: "Topper's Solved these Questions",
  },
  hindi: {
    similarQuestions: "समान प्रश्न",
    related: "संबंधित",
    video: "वीडियो",
    latest_blog_post: "नवीनतम ब्लॉग पोस्ट",
    microconcepts: "पूछे जाने वाले प्रश्न",
    view_all: "सभी को देखें",
    latest_videos: "नवीनतम वीडियो",
    text_solution: "लिखित उत्तर",
    solution: "उत्तर",
    answer: "उत्तर",
    related_answer: "संबंधित उत्तर",
    class: "कक्षा",
    image_solution: "चित्र समाधान",
    very_imporatant_questions: "बहुत महत्वपूर्ण प्रश्न",
    imporatant: "महत्वपूर्ण",
    question: "प्रश्न",
    relatedPlaylists: "संबंधित प्लेलिस्ट",
    videoSolution: "वीडियो समाधान",
    similarPracticeProblems: "समान अभ्यास समस्याएँ",
    knowledgeCheck: "ज्ञान जांच",
    topperSolved: "टॉपर्स ने हल किए ये सवाल",
  },
  telugu: {
    similarQuestions: "సమాన ప్రశ్నలు",
    related: "సంబంధిత",
    video: "వీడియో",
    latest_blog_post: "తాజా బ్లాగ్ పోస్ట్",
    microconcepts: "తరచుగా అడిగే ప్రశ్నలు",
    view_all: "అన్నీ చూడండి",
    latest_videos: "తాజా వీడియోలు",
    text_solution: "వ్రాసిన సమాధానం",
    solution: "సమాధానం",
    answer: "సమాధానం",
    related_answer: "సంబంధిత సమాధానం",
    class: "తరగతి",
    image_solution: "పిక్చర్ రిజల్యూషన్",
    very_imporatant_questions: "చాలా అవ్యక్తమైన ప్రశ్నలు",
    imporatant: "అవ్యక్తమైన",
    question: "ప్రశ్నలు",
    relatedPlaylists: "సంబంధిత ప్లేలిస్ట్",
    videoSolution: "వీడియో సమాధానం",
    similarPracticeProblems: "ఇలాంటి ప్రాక్టీస్ సమస్యలు",
    knowledgeCheck: "నాలెడ్జ్ చెక్",
    topperSolved: "టాపర్ ఈ ప్రశ్నలను పరిష్కరించాడు",
  },
  tamil: {
    similarQuestions: "சமான கேள்விகள்",
    related: "தொடர்புடைய",
    video: "வீடியோ",
    latest_blog_post: "சமீபத்திய வலைப்பதிவு இடுகை",
    microconcepts: "அடிக்கடி கேட்கப்படும் கேள்விகள்",
    view_all: "அனைத்தையும் காட்டு",
    latest_videos: "சமீபத்திய வீடியோக்கள்",
    text_solution: "எழுதப்பட்ட பதில்",
    solution: "பதில்",
    answer: "பதில்",
    related_answer: "தொடர்புடைய பதில்",
    class: "வர்க்கம்",
    image_solution: "படத் தீர்மானம்",
    very_imporatant_questions: "மிக முக்கியமான கேள்வி",
    imporatant: "முக்கியமான",
    question: "கேள்வி",
    relatedPlaylists: "தொடர்புடைய பிளேய்லிஸ்ட்ஸ்",
    videoSolution: "வீடியோ தீர்மானம்",
    similarPracticeProblems: "இதே போன்ற நடைமுறை சிக்கல்கள்",
    knowledgeCheck: "அறிவு சோதனை",
    topperSolved: "இந்த கேள்விகளை டாப்பர் தீர்த்தார்",
  },
  punjabi: {
    similarQuestions: "ਸਮਾਨ ਸਵਾਲ",
    related: "ਸਬੰਧਤ",
    video: "वीडियो",
    latest_blog_post: "ਤਾਜ਼ਾ ਬਲਾੱਗ ਪੋਸਟ",
    microconcepts: "ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਪ੍ਰਸ਼ਨ",
    view_all: "ਸਭ ਨੂੰ ਵੇਖੋ",
    latest_videos: "ਤਾਜ਼ਾ ਵੀਡੀਓ",
    text_solution: "ਲਿਖਤੀ ਜਵਾਬ",
    solution: "ਜਵਾਬ",
    answer: "ਜਵਾਬ",
    related_answer: "ਸਬੰਧਤ ਜਵਾਬ",
    class: "ਕਲਾਸ",
    image_solution: "ਤਸਵੀਰ ਰੈਜ਼ੋਲੇਸ਼ਨ",
    very_imporatant_questions: "ਬਹੁਤ ਹੀ ਮਹੱਤਵਪੂਰਨ ਪ੍ਰਸ਼ਨ",
    imporatant: "ਮਹੱਤਵਪੂਰਨ",
    question: "ਪ੍ਰਸ਼ਨ",
    relatedPlaylists: "ਸਬੰਧਤ ਪਲੇਲਿਸਟ",
    videoSolution: "ਵੀਡੀਓ ਸਲੂਸ਼ਨ",
    similarPracticeProblems: "ਮਿਲਦੇ-ਜੁਲਦੇ ਅਭਿਆਸ ਦੀਆਂ ਸਮੱਸਿਆਵਾਂ",
    knowledgeCheck: "ਗਿਆਨ ਦੀ ਜਾਂਚ",
    topperSolved: "ਟੌਪਰ ਨੇ ਇਹ ਸਵਾਲ ਹੱਲ ਕੀਤੇ"
  },
  bengali: {
    similarQuestions: "সম্পর্কিত প্রশ্ন",
    related: "সংশ্লিষ্ট",
    video: "ভিডিও",
    latest_blog_post: "সর্বশেষ ব্লগ পোস্ট",
    microconcepts: "FAQs",
    view_all: "সব তাকান",
    latest_videos: "সর্বশেষ ভিডিও",
    text_solution: "লিখিত জবাব",
    solution: "উত্তর",
    answer: "উত্তর",
    related_answer: "উত্তর",
    class: "ক্লাস",
    image_solution: "চিত্র রেজোলিউশন",
    very_imporatant_questions: "খুব গুরুত্বপূর্ণ প্রশ্ন",
    imporatant: "গুরুত্বপূর্ণ",
    question: "প্রশ্ন",
    relatedPlaylists: "সম্পর্কিত প্লেলিস্ট",
    videoSolution: "ভিডিও সমাধান",
    similarPracticeProblems: "অনুরূপ অনুশীলন সমস্যা",
    knowledgeCheck: "জ্ঞান পরীক্ষা করুন",
    topperSolved: "টপার এই প্রশ্নগুলো সমাধান করেছে"
  },
  kannada: {
    similarQuestions: "ಸಮಾನ ಪ್ರಶ್ನೆಗಳು",
    related: "ಸಂಬಂಧಿತ",
    video: "ವೀಡಿಯೊ",
    latest_blog_post: "ಇತ್ತೀಚಿನ ಬ್ಲಾಗ್ ಪೋಸ್ಟ್",
    microconcepts: "FAQ ಗಳು",
    view_all: "ಎಲ್ಲವನ್ನೂ ನೋಡಿ",
    latest_videos: "ಇತ್ತೀಚಿನ ವೀಡಿಯೊಗಳು",
    text_solution: "ಲಿಖಿತ ಉತ್ತರ",
    solution: "ಉತ್ತರ",
    answer: "ಉತ್ತರ",
    related_answer: "ಸಂಬಂಧಿತ ಉತ್ತರ",
    class: "ವರ್ಗ",
    image_solution: "ಚಿತ್ರ ರೆಸಲ್ಯೂಶನ್",
    very_imporatant_questions: "ಬಹಳ ಮುಖ್ಯವಾದ ಪ್ರಶ್ನೆ",
    imporatant: "ಮುಖ್ಯವಾದ",
    question: "ಪ್ರಶ್ನೆ",
    relatedPlaylists: "ಸಂಬಂಧಿತ ಪ್ಲೇಲಿಸ್ಟ್",
    videoSolution: "ವೀಡಿಯೊ ಸಮಾಧಾನ",
    similarPracticeProblems: "ಇದೇ ರೀತಿಯ ಅಭ್ಯಾಸದ ತೊಂದರೆಗಳು",
    knowledgeCheck: "ಜ್ಞಾನ ಪರಿಶೀಲನೆ",
    topperSolved: "ಟಾಪರ್ ಈ ಪ್ರಶ್ನೆಗಳನ್ನು ಪರಿಹರಿಸಿದ್ದಾರೆ"
  },
  gujrati: {
    similarQuestions: "સમાન પ્રશ્નો",
    related: "સંબંધિત",
    video: "વિડિઓ",
    latest_blog_post: "નવીનતમ બ્લોગ પોસ્ટ",
    microconcepts: "પ્રશ્નો",
    view_all: "બધા જુઓ",
    latest_videos: "નવીનતમ વિડિઓઝ",
    text_solution: "લેખિત જવાબ",
    solution: "જવાબ",
    answer: "જવાબ",
    related_answer: "સંબંધિત જવાબ",
    class: "વર્ગ",
    image_solution: "ચિત્ર ઠરાવ",
    very_imporatant_questions: "ખૂબ જ મહત્વપૂર્ણ પ્રશ્ન",
    imporatant: "મહત્વપૂર્ણ",
    question: "પ્રશ્ન",
    relatedPlaylists: "સંબંધિત પ્લેલિસ્ટ",
    videoSolution: "વિડિઓ સમાધાન",
    similarPracticeProblems: "સમાન પ્રેક્ટિસ સમસ્યાઓ",
    knowledgeCheck: "જ્ઞાન તપાસ",
    topperSolved: "ટોપરે આ પ્રશ્નો ઉકેલ્યા"
  },
  gujarati: {
    similarQuestions: "સમાન પ્રશ્નો",
    related: "સંબંધિત",
    video: "વિડિઓ",
    latest_blog_post: "નવીનતમ બ્લોગ પોસ્ટ",
    microconcepts: "પ્રશ્નો",
    view_all: "બધા જુઓ",
    latest_videos: "નવીનતમ વિડિઓઝ",
    text_solution: "લેખિત જવાબ",
    solution: "જવાબ",
    answer: "જવાબ",
    related_answer: "સંબંધિત જવાબ",
    class: "વર્ગ",
    image_solution: "ચિત્ર ઠરાવ",
    very_imporatant_questions: "ખૂબ જ મહત્વપૂર્ણ પ્રશ્ન",
    imporatant: "મહત્વપૂર્ણ",
    question: "પ્રશ્ન",
    relatedPlaylists: "સંબંધિત પ્લેલિસ્ટ",
    videoSolution: "વિડિઓ સમાધાન",
    similarPracticeProblems: "સમાન પ્રેક્ટિસ સમસ્યાઓ",
    knowledgeCheck: "જ્ઞાન તપાસ",
    topperSolved: "ટોપરે આ પ્રશ્નો ઉકેલ્યા"
  },
};

export type MediaType =
  | "video/mp4"
  | "application/dash+xml"
  | "application/x-mpegURL";

export const DEEPLINK = {
  APP_DEFAULT: "https://doubtnut.app.link/VJIHNKWLMxb",
};

export const STUDENT_CLASS = [6, 7, 8, 9, 10, 11, 12, 13, 14];

export const BACKGROUND_COLORS_TESTIMONIALS = [
  "bg-[#9d789b]",
  "bg-[#7a81a8]",
  "bg-[#6d5b98]",
];

export const BACKGROUND_COLORS_TEACHERS = [
  "bg-[#FFE0B2]",
  "bg-[#FFCBCB]",
  "bg-[#BEEBB7]",
  "bg-[#B9DEF3]"
];

export const THEME_OPTIONS = [
  {
    label: "Light",
    value: "light"
  },
  {
    label: "Dark",
    value: "dark"
  },
];

export const modalSelectedStyle = "bg-[#eb532c] dark:!bg-[#eb532c] text-white rounded-md";

export const LEAD_FORM_AUTO_OPEN_EXCLUSION_ROUTES = ["/seminar"];

export const PREBID_EVENTS = ["addAdUnits", "auctionDebug", "requestBids", "addAdUnits", "auctionDebug", "requestBids", "beforeRequestBids", "auctionInit", "bidRequested", "beforeBidderHttp", "bidRequested", "beforeBidderHttp", "bidRequested", "beforeBidderHttp", "beforeRequestBids", "auctionInit", "bidRequested", "beforeBidderHttp", "bidRequested", "beforeBidderHttp", "bidRequested", "beforeBidderHttp", "noBid", "bidderDone", "noBid", "bidderDone", "bidAdjustment", "bidResponse", "bidderDone", "bidAdjustment", "bidResponse", "bidderDone", "noBid", "auctionEnd", "tcf2Enforcement", "setTargeting", "bidderDone", "noBid", "auctionEnd", "tcf2Enforcement", "setTargeting", "bidderDone", "bidWon", "adRenderSucceeded", "bidWon", "adRenderSucceeded", "auctionDebug", "setTargeting", "bidViewable"];